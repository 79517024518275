@import './../../styling/variables.scss';

.about-us {
  font-size: 24px;
  color: $dark-grey;
  padding: 32px 0;
  margin-top: 100px;
  text-align: center;

  @media (max-width: 992px) {
    margin-top: 0;
  }

  &__description {
    font-family: $praxis;
    padding: 60px 150px 70px;
    font-size: 20px;
    border-top: 1px solid $dark-grey;

    @media (max-width: 992px) {
      padding: 24px 24px 35px;
      margin-bottom: 40px;
      border-top: none;
    }
  }

  &__experience {
    font-family: 'Praxis', sans-serif;
    font-size: 20px;
    padding: 35px 24px;
  }
}

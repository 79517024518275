@import './../../../styling/variables.scss';

.profiles {
  $profiles: &;

  padding: 32px;
  margin-top: 24px;
  text-align: center;

  @media (max-width: 992px) {
    color: $dark-grey;
    padding: 40px 0 0;
    margin-top: 0;
    background-color: rgba(207, 209, 208, 0.45);
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }

  &__bios-wrapper {
    display: flex;
    justify-content: space-around;

    @media (max-width: 992px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__profile-wrapper {
    padding: 0 24px;

    @media (max-width: 992px) {
      padding: 0;


      &:last-child {
        padding: 40px 0 32px;
        margin-top: 30px;
        background-color: rgba(0, 117, 201, 1);
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        color: $white;

        #{$profiles}__description-button {
          color: $white;
        }
      }
    }
  }

  &__header {
    color: $white;
    background-color: $royal-blue;
    font-size: 20px;
    margin: 0 auto;
    padding: 0;
    
    @media (min-width: 992px) {
      border-radius: 100px;
      position: relative;
      bottom: 80px;
      padding: 32px; 
      max-width: 700px;
    }
  }

  &__profile {
    text-align: center;
  }

  &__description {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 992px) {
      height: unset;
    }

    &-wrapper {
      min-height: 200px;
      display: flex;
      justify-content: center;

      @media (max-width: 992px) {
        padding: 0 16px;
      }
    }
  }

  &__description,
  &__description-expanded {
    max-width: 500px;
    font-size: 20px;

    &-title {
      font-size: 28px;
    }

    &-button {
      border: none;
      background: none;
      color: $royal-blue;
      font-size: 20px;
      font-weight: bold;
      font-style: italic;
      width: 100%;
      padding-top: 20px;

      &:after {
        content: '\00bb';
        position: relative;
        opacity: 0;
        bottom: 20;
        right: -50px;
        transition: 0.5s;
      }

      &:hover {
        color: $green;
        transform: translateX(-15px);

        &:after {
          opacity: 1;
          right: 0;
          padding-right: 50px;
          position: absolute;
        }
      }

      @media (max-width: 992px) {
        max-width: none;
        color: $dark-grey;

        &:after {
          content: '';
        }

        &:hover {
          color: $dark-grey;
          transform: none;
        }
      }
    }
  }

  &__image {
    margin: 30px auto;
  }

  img {
    width: auto;
    height: 350px;

    @media (max-width: 992px) {
      height: 300px;
    }
  }
}

@import './../../styling/variables.scss';

.contact-form {
  margin: 40px 0;
  background-color: rgba(207, 209, 208, 0.6);
  box-shadow: 5px 5px 10px $light-grey;
  border-radius: 50px;
  padding: 30px;

  @media (max-width: 992px) {
    box-shadow: none;
    margin: 0;
    width: auto;
    border-radius: 30px;
  }

  &-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin: 50px auto 80px;

    @media (max-width: 992px) {
      text-align: center;
      display: block;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media (max-width: 992px) {
      margin: 0 0 16px 0;
    }
  }

  &__title {
    font-family: $lato;
    font-size: 20px;
    font-weight: bold;
    color: $dark-grey;
    text-align: center;
    width: 100%;
    padding: 24px 0 10px;

    @media (max-width: 992px) {
      font-size: 20px;
    }
  }

  &__form {
    display: flex;
    text-align: center;
    align-items: center;
    border-radius: 8px;

    &-message {
      height: 100px;
    }

    &-content {
      min-width: 300px;
    }

    @media (max-width: 992px) {
      padding: 0 16px;
      flex-direction: column;
      margin-top: -20px;

      //&-content,
      &-message {
        width: 100%;
      }
    }
  }

  &__submit {
    font-family: $praxis;
    color: $white;
    font-weight: 600;
    background-color: $royal-blue;
    margin: 12px 30px;
    width: 200px;
    height: fit-content;

    &:hover {
      background-color: $green;
      color: $white;
    }

    &:focus {
      color: $royal-blue;
      background-color: $white;
    }
  }

  input,
  textarea {
    max-width: 400px;
  }

  input {
    margin-bottom: 16px;
  }
}

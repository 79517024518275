@import './../../../../styling/variables.scss';

.area-of-expertise-tile {
  $area-of-expertise-tile: &;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 200px;
  height: 275px;
  padding: 8px;
  box-shadow: 8px 5px 5px $light-grey;
  background-color: rgba(0, 117, 201, .6);
  border-radius: 6px;
  transition: background-color 700ms;

  &__title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 16px;
    padding: 4px 0 8px;

    @media (max-width: 992px) {
      margin-bottom: 0;
      height: unset;
    }
  }

  &__description {
    display: none;
    font-size: 16px;
  }

  &-wrapper {
    padding: 20px;
    display: flex;
    justify-content: center;

    &:hover {
      transform: translateY(-15px);
    }
  }

  &:hover {
    background-color: rgba(0, 117, 201, .9);
    cursor: pointer;
    justify-content: flex-start;

    #{$area-of-expertise-tile}__title {
      border-bottom: 1px solid $white;
      padding-bottom: 12px;
      height: fit-content;
      font-size: 16px;
    }

    #{$area-of-expertise-tile}__description {
      display: flex;
      font-size: 15px;
    }
  }

  &-mobile {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    padding: 4px 12px;
    box-shadow: 8px 5px 5px $light-grey;
    background-color: $royal-blue;

    &__expanded {
      height: fit-content;
      display: block;
      background-color: rgba(106, 191, 75, .8);

      #{$area-of-expertise-tile}__description {
        font-size: 18px;
        max-width: 400px;
        margin: 6px auto;
        display: flex;
      }

      #{$area-of-expertise-tile}__title {
        font-size: 20px;
        border-bottom: 1.5px solid $white;
        padding-bottom: 8px;
      }
    }
  }

  &__title,
  &__description {
    color: $white;
    font-family: $praxis;
  }

  .grid-x {
    display: flex;
  }
}

@import './../../styling/variables.scss';

.footer {
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
  align-items: center;
  color: $dark-grey;
  font-family: $praxis;
  font-size: 16px;;
  margin: 0 30px;

  @media (max-width: 992px) {
    margin: 0;
    justify-content: center;
    text-align: center;
  }

  img {
    max-width: 150px;
    max-height: auto;
  }
}
@import './../../styling/variables.scss';

.nav-bar {
  display: block;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: $white;
  padding-bottom: 16px;
  font-family: $praxis;
  font-size: 20px;

  @media (min-width: 992px) {
    display: flex;
    justify-content: space-between;
  }

  &--buttons {
    text-align: center;

    @media (max-width: 992px) {
      margin-top: 16px;
    }
  }

  img {
    max-width: 150px;
    max-height: auto;
  }

  a {
    > button {
      justify-content: space-between;
      background: none;
      border: none;
      color: $dark-grey;
      font-family: $praxis;
      text-transform: uppercase;
      font-size: 16px;

      &:hover {
        @media (min-width: 992px) {
          cursor: pointer;
          color: $royal-blue;
        }
      }

      @media (min-width: 992px) {
        font-size: 16px;
        margin: 0 16px;
      }
    }
  }

  &--logo {
    @media (max-width: 992px) {
      text-align: center;
    }
  }
}
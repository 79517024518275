@import './../../styling/variables.scss';

.clients {
  margin-top: 20px;
  
  &__title {
    font-family: $praxis;
    font-size: 20px;
    color: $dark-grey;
    width: 100%;
    text-align: center;
    font-weight: bold;

    @media (max-width: 992px) {
      font-size: 20px;
    }
  }

  &__logos {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    list-style: none;
    margin: 25px 0;
    border-top: 1px solid $dark-grey;
    border-bottom: 1px solid $dark-grey;
    padding: 24px 0;

    img {
      width: 250px;
      height: auto;

      @media (max-width: 992px) {
        width: 200px;
      }
    }

    @media (max-width: 992px) {
      margin: 25px;
    }
  }

  li {
    margin: 12px;
  }

  &__logo-extended {
    margin-bottom: 50px;

    @media (max-width: 992px) {
      margin-bottom: 30px;
    }
  }

  @media (max-width: 992px) {
    padding-bottom: 0;
  }
}

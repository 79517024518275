@import './styling/variables.scss';

.razr-consulting {
  background-color: $white;

  & > div {
    //padding: 32px;
  }

  & > nav {
    padding: 20px 30px;

    @media (min-width: 992px) {
      padding: 20px 40px;
    }
  }

  .areas-of-expertise-wrapper, .about-us {
    padding: 20px 0;
  }
}

@import './../../styling/variables.scss';

.header {
  height: 100%;

  &-wrapper {
    min-height: 350px;
    color: $dark-grey;
    display: flex;
    align-items: flex-end;

    @media (max-width: 992px) {
      min-height: auto;
      text-align: center;
      padding: 20px;
    }
  }

  &__subtitles {
    font-family: $praxis;
    font-weight: bolder;
    font-size: 14px;
    color: $royal-blue;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 8px;
    white-space: nowrap;

    @media (max-width: 992px) {
      font-size: 12px;
    }

    p {
      margin: 0 8px;
    }
  }

  &__title {
    font-family: $praxis;
    font-size: 50px;
    padding: 0 0 16px;

    @media (max-width: 992px) {
      font-size: 25px;
    }
  }

  &__banner {
    display: flex;
    overflow-x: clip;

    @media (max-width: 992px) {
      overflow-x: visible;
      display: flex;
      flex-direction: column;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (min-width: 992px) {
      margin-right: -80px;
      position: relative;
      top: 80px;
      left: 30px;
    }
  }

  &__info {
    font-family: $praxis;
    font-size: 25px;
    display: flex;
    height: fit-content;

    @media (max-width: 992px) {
      font-size: 20px;
      max-width: 100%;
    }
  }

  img {
    max-width: 450px;
    height: auto;
    margin-left: auto;
    transform: rotate(110deg);
    position: relative;
    top: 60px;
    right: 20px;
  }
}
